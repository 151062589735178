// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Result from "rescript/lib/es6/belt_Result.js";
import * as ProjectTile from "../../../../models/ProjectTile.res.js";
import * as RailsContext from "../../../../models/RailsContext.res.js";
import * as UserProjects from "./UserProjects.res.js";
import * as ProviderAsFilter from "../../../../models/ProviderAsFilter.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ProjectsViewContext from "../../../../models/ProjectsViewContext.res.js";
import * as Json_Decode$JsonCombinators from "@glennsl/rescript-json-combinators/src/Json_Decode.res.js";
import * as UserProjects_List_Filter_Data from "./UserProjects_List_Filter_Data.res.js";

function UserProjects__JsBridge$default(props) {
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var isConcierge = props.isConcierge;
  var viewContext = props.viewContext;
  var railsContext = props.railsContext;
  var searchParams = props.searchParams;
  var filters = props.filters;
  var projects = props.projects;
  var railsContext$1 = React.useMemo((function () {
          return RailsContext.fromJson(railsContext);
        }), [railsContext]);
  var projects$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(projects, Json_Decode$JsonCombinators.array(ProjectTile.decoder)));
        }), [projects]);
  var providers = React.useMemo((function () {
          return Belt_Array.map(filters.providers, ProviderAsFilter.fromJs);
        }), [filters.providers]);
  var filters$1 = React.useMemo((function () {
          return UserProjects_List_Filter_Data.Input.fromJs(searchParams);
        }), [searchParams]);
  var userViewContext = React.useMemo((function () {
          return ProjectsViewContext.fromString(viewContext);
        }), [viewContext]);
  var isConcierge$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(isConcierge, Json_Decode$JsonCombinators.bool));
        }), [isConcierge]);
  var isNotOnlyProviderMember$1 = React.useMemo((function () {
          return Belt_Result.getExn(Json_Decode$JsonCombinators.decode(isNotOnlyProviderMember, Json_Decode$JsonCombinators.bool));
        }), [isNotOnlyProviderMember]);
  return JsxRuntime.jsx(UserProjects.make, {
              projects: projects$1,
              providers: providers,
              filters: filters$1,
              userRole: railsContext$1.userRole,
              userViewContext: userViewContext,
              totalProjects: props.totalProjects,
              currentPage: props.currentPage,
              totalPages: props.totalPages,
              isConcierge: isConcierge$1,
              isNotOnlyProviderMember: isNotOnlyProviderMember$1,
              mobile: props.mobile,
              tablet: props.tablet
            });
}

var $$default = UserProjects__JsBridge$default;

export {
  $$default as default,
}
/* react Not a pure module */
