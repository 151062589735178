// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as IconOk from "../../../../styleguide/icons/IconOk.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as Project from "../../../../models/Project.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as IconPause from "../../../../styleguide/icons/IconPause.res.js";
import * as NoBidModal from "../../../../styleguide/modals/NoBidModal/NoBidModal.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PreparingProposalModal from "../../../../styleguide/modals/PreparingProposalModal/PreparingProposalModal.res.js";
import * as UserProjects_List_ControlsScss from "./UserProjects_List_Controls.scss";

var css = UserProjects_List_ControlsScss;

function reducer(state, action) {
  if (action === "ToggleNoBidModal") {
    return {
            isNoBidModalShown: !state.isNoBidModalShown,
            isPreparingProposalModalShown: state.isPreparingProposalModalShown
          };
  } else {
    return {
            isNoBidModalShown: state.isNoBidModalShown,
            isPreparingProposalModalShown: !state.isPreparingProposalModalShown
          };
  }
}

function UserProjects_List_ControlsForAdmin(props) {
  var userRole = props.userRole;
  var projectData = props.projectData;
  var projectId = props.projectId;
  var match = React.useReducer(reducer, {
        isNoBidModalShown: false,
        isPreparingProposalModalShown: false
      });
  var dispatch = match[1];
  var state = match[0];
  var total = projectData.totalProposals;
  var match$1 = projectData.status;
  var tmp;
  switch (match$1) {
    case "Active" :
        var match$2 = projectData.totalProposals;
        var exit = 0;
        if (match$2 !== undefined && match$2 !== 0) {
          var canSendProposals = projectData.canSendProposals;
          tmp = JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx(Button.AsLink.make, {
                        href: Routes_Project.Proposal.index(projectId),
                        size: "SM",
                        color: "White",
                        children: "View Proposals"
                      }),
                  canSendProposals !== undefined && canSendProposals ? JsxRuntime.jsxs(Popover.make, {
                          children: [
                            JsxRuntime.jsx(Popover.Trigger.make, {
                                  className: css.popoverProjectActions,
                                  children: JsxRuntime.jsx(IconMenu.make, {
                                        size: "LG",
                                        color: "Teal"
                                      })
                                }),
                            JsxRuntime.jsxs(Popover.Body.make, {
                                  position: {
                                    TAG: "Below",
                                    _0: "RightEdge"
                                  },
                                  className: Cx.cx([
                                        css.popoverBody,
                                        css.popoverBodyActionsAdmin,
                                        css.popoverBodyPositionAdmin
                                      ]),
                                  children: [
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx("div", {
                                                children: "Send Proposal",
                                                className: css.sortSelection,
                                                onClick: (function (param) {
                                                    Url.visit(Routes_Project.Proposal.$$new(projectId));
                                                  })
                                              }),
                                          className: css.popoverRow
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx("div", {
                                                children: "Set Preparing Status",
                                                className: css.sortSelection,
                                                onClick: (function (param) {
                                                    dispatch("TogglePreparingProposalModal");
                                                  })
                                              }),
                                          className: css.popoverRow
                                        }),
                                    JsxRuntime.jsx("div", {
                                          children: JsxRuntime.jsx("div", {
                                                children: "No Bid",
                                                className: css.sortSelection,
                                                onClick: (function (param) {
                                                    dispatch("ToggleNoBidModal");
                                                  })
                                              }),
                                          className: css.popoverRow
                                        })
                                  ]
                                })
                          ]
                        }) : null
                ],
                className: css.projectStatusControl
              });
        } else {
          exit = 1;
        }
        if (exit === 1) {
          tmp = JsxRuntime.jsxs("div", {
                children: [
                  JsxRuntime.jsx(Button.AsLink.make, {
                        href: Routes_Project.Proposal.$$new(projectId),
                        size: "SM",
                        color: "Teal",
                        margin: "RightMargin",
                        children: "Send Proposal"
                      }),
                  JsxRuntime.jsxs(Popover.make, {
                        children: [
                          JsxRuntime.jsx(Popover.Trigger.make, {
                                className: css.popoverIconActions,
                                children: JsxRuntime.jsx(Button.make, {
                                      size: "XS",
                                      color: "Teal",
                                      buttonClassName: css.popoverButton,
                                      children: JsxRuntime.jsx(IconMenu.make, {
                                            size: "LG",
                                            color: "White"
                                          })
                                    })
                              }),
                          JsxRuntime.jsxs(Popover.Body.make, {
                                position: {
                                  TAG: "Below",
                                  _0: "RightEdge"
                                },
                                className: Cx.cx([
                                      css.popoverBody,
                                      css.popoverBodyActionsAdmin,
                                      css.popoverBodyPositionAdmin
                                    ]),
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: "Set Preparing Status",
                                              className: css.sortSelection,
                                              onClick: (function (param) {
                                                  dispatch("TogglePreparingProposalModal");
                                                })
                                            }),
                                        className: css.popoverRow
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: "No Bid",
                                              className: css.sortSelection,
                                              onClick: (function (param) {
                                                  dispatch("ToggleNoBidModal");
                                                })
                                            }),
                                        className: css.popoverRow
                                      })
                                ]
                              })
                        ]
                      })
                ],
                className: css.projectStatusControl
              });
        }
        break;
    case "Inactive" :
        tmp = JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(IconPause.make, {
                                    size: "SM",
                                    color: "White"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: Project.Status.toString(projectData.status)
                                  })
                            ],
                            className: css.pillTitle
                          }),
                      className: css.projectStatusInactive
                    }),
                JsxRuntime.jsx(A.make, {
                      href: Routes_Project.Proposal.index(projectId),
                      className: css.link,
                      children: "View Proposals"
                    })
              ],
              className: css.projectStatusControl
            });
        break;
    case "Finalized" :
        tmp = JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(IconOk.make, {
                                    size: "SM",
                                    color: "White"
                                  }),
                              JsxRuntime.jsx("span", {
                                    children: Project.Status.toString(projectData.status)
                                  })
                            ],
                            className: css.pillTitle
                          }),
                      className: css.projectStatusFinalized
                    }),
                JsxRuntime.jsx(A.make, {
                      href: Routes_Project.Proposal.index(projectId),
                      className: css.link,
                      children: "View Proposals"
                    })
              ],
              className: css.projectStatusControl
            });
        break;
    case "Draft" :
    case "Review" :
        tmp = null;
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Proposals",
                              className: css.proposalLabel
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("span", {
                                      children: String(total !== undefined ? total : 0)
                                    }),
                                " sent"
                              ],
                              className: css.projectLocationAndProviders
                            }),
                        tmp
                      ],
                      className: css.projectLocations
                    }),
                state.isNoBidModalShown ? JsxRuntime.jsx(NoBidModal.make, {
                        title: "No Bid",
                        close: (function () {
                            dispatch("ToggleNoBidModal");
                          }),
                        projectId: projectId,
                        userRole: userRole
                      }) : null,
                state.isPreparingProposalModalShown ? JsxRuntime.jsx(PreparingProposalModal.make, {
                        title: "Set Preparing Status",
                        close: (function () {
                            dispatch("TogglePreparingProposalModal");
                          }),
                        projectId: projectId,
                        userRole: userRole
                      }) : null
              ],
              className: Cx.cx([
                    css.projectProviderAdminControls,
                    projectData.status === "Review" ? css.smallHeight : css.largeHeight
                  ])
            });
}

var make = UserProjects_List_ControlsForAdmin;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
