// Generated by ReScript, PLEASE EDIT WITH CARE

import * as A from "../../../../styleguide/components/Link/A.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as Project from "../../../../models/Project.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as TopNavbar from "../../top-navbar/TopNavbar.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconSelect from "../../../../styleguide/icons/IconSelect.res.js";
import * as ProjectTile from "../../../../models/ProjectTile.res.js";
import * as IconDownload from "../../../../styleguide/icons/IconDownload.res.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as AdminTopNavbar from "../../../dashboard/common/admin-top-navbar/AdminTopNavbar.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as UserProjects_List from "./UserProjects_List.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConciergeTopNavbar from "../../../dashboard/common/concierge-top-navbar/ConciergeTopNavbar.res.js";
import * as UserProjectsScss from "./UserProjects.scss";
import * as RescriptReactRouter from "@rescript/react/src/RescriptReactRouter.res.js";
import * as FetchProjectsRequest from "../../../../api/requests/FetchProjectsRequest.res.js";
import * as UserProjects_List_Input from "./UserProjects_List_Input.res.js";
import * as UserProjects_List_Filter_Status from "./UserProjects_List_Filter_Status.res.js";
import * as UserProjects_List_Filter_Category from "./UserProjects_List_Filter_Category.res.js";
import * as UserProjects_List_Filter_Provider from "./UserProjects_List_Filter_Provider.res.js";

var css = UserProjectsScss;

function reducer(state, action) {
  switch (action.TAG) {
    case "Paginate" :
        return {
                projects: state.projects,
                filters: state.filters,
                currentPage: action._0,
                totalProjects: state.totalProjects,
                totalPages: state.totalPages
              };
    case "UpdateFilter" :
        return {
                projects: state.projects,
                filters: action._0,
                currentPage: 1,
                totalProjects: state.totalProjects,
                totalPages: state.totalPages
              };
    case "FetchProjects" :
        var res = action._0;
        return {
                projects: res.projects,
                filters: state.filters,
                currentPage: state.currentPage,
                totalProjects: res.totalProjects,
                totalPages: res.totalPages
              };
    case "UpdateProjectStatus" :
        var project = action._0;
        return {
                projects: Belt_Array.map(state.projects, (function (item) {
                        if (ProjectTile.eq(item, project)) {
                          return project;
                        } else {
                          return item;
                        }
                      })),
                filters: state.filters,
                currentPage: state.currentPage,
                totalProjects: state.totalProjects,
                totalPages: state.totalPages
              };
    case "UpdateProjectProposal" :
        var proposal = action._1;
        var projectId = action._0;
        return {
                projects: Belt_Array.map(state.projects, (function (item) {
                        if (item.TAG === "Draft") {
                          return item;
                        }
                        var data = item.data;
                        var id = item.id;
                        if (Project.Id.$eq(id, projectId)) {
                          return {
                                  TAG: "Project",
                                  id: id,
                                  category: item.category,
                                  data: {
                                    title: data.title,
                                    description: data.description,
                                    startDate: data.startDate,
                                    endDate: data.endDate,
                                    contact: data.contact,
                                    businessName: data.businessName,
                                    totalLocations: data.totalLocations,
                                    totalProviders: data.totalProviders,
                                    totalProposals: data.totalProposals,
                                    canSendProposals: data.canSendProposals,
                                    status: data.status,
                                    proposalsBreakdown: data.proposalsBreakdown,
                                    providerLogo: data.providerLogo,
                                    providerName: data.providerName,
                                    proposalDetails: proposal,
                                    createdAt: data.createdAt,
                                    category: data.category
                                  }
                                };
                        } else {
                          return item;
                        }
                      })),
                filters: state.filters,
                currentPage: state.currentPage,
                totalProjects: state.totalProjects,
                totalPages: state.totalPages
              };
    
  }
}

function UserProjects(props) {
  var isNotOnlyProviderMember = props.isNotOnlyProviderMember;
  var isConcierge = props.isConcierge;
  var userViewContext = props.userViewContext;
  var userRole = props.userRole;
  var providers = props.providers;
  var match = React.useReducer(reducer, {
        projects: props.projects,
        filters: props.filters,
        currentPage: props.currentPage,
        totalProjects: props.totalProjects,
        totalPages: props.totalPages
      });
  var dispatch = match[1];
  var state = match[0];
  var fetchProjects = function () {
    $$Promise.wait(Api.fetchProjects(state.currentPage, state.filters, userViewContext, isConcierge), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "FetchProjects",
                          _0: x._0
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "UserProjects",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "UserProjects.make"
                        }, "FetchProjects::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var updateProjectStatus = function (projectId, projectStatus) {
    $$Promise.wait(Api.updateProjectStatus(projectId, projectStatus), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "UpdateProjectStatus",
                          _0: x._0.project
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "UserProjects",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "UserProjects.make"
                        }, "UpdateStatus::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  var updateProposalStatus = function (projectId, proposal, message, param) {
    $$Promise.wait(Api.updateProjectProposalStatus(projectId, proposal, message, undefined), (function (x) {
            if (x.TAG === "Ok") {
              return dispatch({
                          TAG: "UpdateProjectProposal",
                          _0: projectId,
                          _1: x._0.proposal
                        });
            } else {
              return SentryLogger.error1({
                          rootModule: "UserProjects",
                          subModulePath: /* [] */0,
                          value: "make",
                          fullPath: "UserProjects.make"
                        }, "UpdateProposalStatus::Error", [
                          "Error",
                          x._0
                        ]);
            }
          }));
  };
  React.useEffect((function () {
          RescriptReactRouter.push("?" + FetchProjectsRequest.queryString(state.currentPage, state.filters, userViewContext, isConcierge));
          fetchProjects();
        }), [
        state.currentPage,
        state.filters
      ]);
  var tmp;
  switch (userViewContext) {
    case "Admin" :
        switch (userRole) {
          case "Admin" :
              tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
                    selectedCategory: isConcierge ? "ConciergeProjects" : "Projects",
                    isAdmin: true,
                    isConcierge: isConcierge,
                    isNotOnlyProviderMember: isNotOnlyProviderMember
                  });
              break;
          case "Provider" :
              tmp = JsxRuntime.jsx(AdminTopNavbar.make, {
                    selectedCategory: isConcierge ? "ConciergeProjects" : "Projects",
                    isAdmin: false,
                    isConcierge: isConcierge,
                    isNotOnlyProviderMember: isNotOnlyProviderMember
                  });
              break;
          case "User" :
          case "Visitor" :
              tmp = null;
              break;
          case "Concierge" :
          case "Agent" :
              tmp = isConcierge ? JsxRuntime.jsx(ConciergeTopNavbar.make, {
                      selectedCategory: "Projects"
                    }) : null;
              break;
          
        }
        break;
    case "Personal" :
    case "Visitor" :
        tmp = JsxRuntime.jsx(TopNavbar.make, {
              selectedCategory: "Projects"
            });
        break;
    
  }
  var tmp$1;
  switch (userViewContext) {
    case "Admin" :
        tmp$1 = isConcierge ? "Concierge Projects" : "Provider Projects";
        break;
    case "Personal" :
    case "Visitor" :
        tmp$1 = "Personal Projects";
        break;
    
  }
  var tmp$2;
  switch (userViewContext) {
    case "Personal" :
        var tmp$3;
        var exit = 0;
        switch (userRole) {
          case "Admin" :
          case "Concierge" :
          case "Agent" :
              exit = 1;
              break;
          default:
            tmp$3 = null;
        }
        if (exit === 1) {
          tmp$3 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Control.make, {
                              className: css.newProjectMenuItemControl,
                              onClick: (function (param) {
                                  Url.visit(Routes_Project.Internet.$$new);
                                }),
                              children: "Internet RFP"
                            }),
                        className: css.newProjectMenuItem
                      }),
                  JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsx(Control.make, {
                              className: css.newProjectMenuItemControl,
                              onClick: (function (param) {
                                  Url.visit(Routes_Project.Network.$$new);
                                }),
                              children: "Network RFP"
                            }),
                        className: css.newProjectMenuItem
                      })
                ]
              });
        }
        tmp$2 = JsxRuntime.jsxs(Popover.make, {
              className: css.buttonContainer,
              children: [
                JsxRuntime.jsx(Popover.Trigger.make, {
                      className: css.buttonContainer,
                      children: JsxRuntime.jsx(Button.make, {
                            size: "SM",
                            color: "Primary",
                            children: "Add New Project"
                          })
                    }),
                JsxRuntime.jsxs(Dropdown.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "RightEdge"
                      },
                      className: css.newProjectMenu,
                      children: [
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Control.make, {
                                    className: css.newProjectMenuItemControl,
                                    onClick: (function (param) {
                                        Url.visit(Routes_Project.BareMetal.$$new);
                                      }),
                                    children: "Bare Metal RFP"
                                  }),
                              className: css.newProjectMenuItem
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Control.make, {
                                    className: css.newProjectMenuItemControl,
                                    onClick: (function (param) {
                                        Url.visit(Routes_Project.Colocation.$$new);
                                      }),
                                    children: "Colocation RFP"
                                  }),
                              className: css.newProjectMenuItem
                            }),
                        JsxRuntime.jsx("div", {
                              children: JsxRuntime.jsx(Control.make, {
                                    className: css.newProjectMenuItemControl,
                                    onClick: (function (param) {
                                        Url.visit(Routes_Project.Cloud.$$new);
                                      }),
                                    children: "Cloud RFP"
                                  }),
                              className: css.newProjectMenuItem
                            }),
                        tmp$3
                      ]
                    })
              ]
            });
        break;
    case "Admin" :
    case "Visitor" :
        tmp$2 = null;
        break;
    
  }
  var tmp$4;
  if (state.projects.length !== 0) {
    var x = state.totalProjects;
    var match$1 = props.mobile || props.tablet;
    var tmp$5;
    tmp$5 = userRole === "Admin" && !match$1 ? JsxRuntime.jsx("div", {
            children: JsxRuntime.jsx(A.make, {
                  href: Routes_Project.toCsv,
                  children: JsxRuntime.jsx(IconDownload.make, {
                        title: "Download",
                        size: "SM",
                        color: "DarkerGray"
                      })
                }),
            className: css.downloadIcon
          }) : null;
    var sortBy = state.filters.sortBy;
    var tmp$6;
    if (sortBy !== undefined && typeof sortBy === "object") {
      var variant = sortBy.NAME;
      tmp$6 = variant === "Created" ? (
          sortBy.VAL === "Desc" ? "Newest" : "Oldest"
        ) : (
          variant === "End" ? (
              sortBy.VAL === "Desc" ? "Ends later" : "Ends sooner"
            ) : (
              variant === "Start" ? (
                  sortBy.VAL === "Desc" ? "Starts later" : "Starts sooner"
                ) : "Choose a sort criteria"
            )
        );
    } else {
      tmp$6 = "Choose a sort criteria";
    }
    tmp$4 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsxs("div", {
                  children: [
                    JsxRuntime.jsx("div", {
                          children: x !== 1 ? String(x) + " projects" : "1 project",
                          className: css.projectsCount
                        }),
                    JsxRuntime.jsx("div", {
                          children: JsxRuntime.jsxs(Popover.make, {
                                children: [
                                  tmp$5,
                                  JsxRuntime.jsxs(Popover.Trigger.make, {
                                        className: css.popoverTrigger,
                                        children: [
                                          JsxRuntime.jsx(Control.AsLink.make, {
                                                color: "Gray",
                                                onClick: (function (param) {
                                                    
                                                  }),
                                                children: tmp$6
                                              }),
                                          JsxRuntime.jsx(IconSelect.make, {
                                                size: "XXXS",
                                                color: "GrayText"
                                              })
                                        ]
                                      }),
                                  JsxRuntime.jsxs(Popover.Body.make, {
                                        position: {
                                          TAG: "Below",
                                          _0: "RightEdge"
                                        },
                                        className: css.popoverBody,
                                        children: [
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Newest",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          var init = state.filters;
                                                          dispatch({
                                                                TAG: "UpdateFilter",
                                                                _0: {
                                                                  query: init.query,
                                                                  providerIds: init.providerIds,
                                                                  statuses: init.statuses,
                                                                  sortBy: {
                                                                    NAME: "Created",
                                                                    VAL: "Desc"
                                                                  },
                                                                  categories: init.categories
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Oldest",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          var init = state.filters;
                                                          dispatch({
                                                                TAG: "UpdateFilter",
                                                                _0: {
                                                                  query: init.query,
                                                                  providerIds: init.providerIds,
                                                                  statuses: init.statuses,
                                                                  sortBy: {
                                                                    NAME: "Created",
                                                                    VAL: "Asc"
                                                                  },
                                                                  categories: init.categories
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Starts sooner",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          var init = state.filters;
                                                          dispatch({
                                                                TAG: "UpdateFilter",
                                                                _0: {
                                                                  query: init.query,
                                                                  providerIds: init.providerIds,
                                                                  statuses: init.statuses,
                                                                  sortBy: {
                                                                    NAME: "Start",
                                                                    VAL: "Asc"
                                                                  },
                                                                  categories: init.categories
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Starts later",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          var init = state.filters;
                                                          dispatch({
                                                                TAG: "UpdateFilter",
                                                                _0: {
                                                                  query: init.query,
                                                                  providerIds: init.providerIds,
                                                                  statuses: init.statuses,
                                                                  sortBy: {
                                                                    NAME: "Start",
                                                                    VAL: "Desc"
                                                                  },
                                                                  categories: init.categories
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Ends sooner",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          var init = state.filters;
                                                          dispatch({
                                                                TAG: "UpdateFilter",
                                                                _0: {
                                                                  query: init.query,
                                                                  providerIds: init.providerIds,
                                                                  statuses: init.statuses,
                                                                  sortBy: {
                                                                    NAME: "End",
                                                                    VAL: "Asc"
                                                                  },
                                                                  categories: init.categories
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              }),
                                          JsxRuntime.jsx("div", {
                                                children: JsxRuntime.jsx("div", {
                                                      children: "Ends later",
                                                      className: css.sortSelection,
                                                      onClick: (function (param) {
                                                          var init = state.filters;
                                                          dispatch({
                                                                TAG: "UpdateFilter",
                                                                _0: {
                                                                  query: init.query,
                                                                  providerIds: init.providerIds,
                                                                  statuses: init.statuses,
                                                                  sortBy: {
                                                                    NAME: "End",
                                                                    VAL: "Desc"
                                                                  },
                                                                  categories: init.categories
                                                                }
                                                              });
                                                        })
                                                    }),
                                                className: css.popoverRow
                                              })
                                        ]
                                      })
                                ]
                              }),
                          className: css.sorting
                        })
                  ],
                  className: css.sortAndCount
                }),
            JsxRuntime.jsx(UserProjects_List.make, {
                  projects: state.projects,
                  userRole: userRole,
                  totalPages: state.totalPages,
                  currentPage: state.currentPage,
                  updateProjectStatus: updateProjectStatus,
                  updateProposalStatus: updateProposalStatus,
                  onPageClick: (function (pageNum) {
                      dispatch({
                            TAG: "Paginate",
                            _0: pageNum
                          });
                    }),
                  userViewContext: userViewContext
                })
          ]
        });
  } else {
    tmp$4 = JsxRuntime.jsx("div", {
          children: "No projects found",
          className: css.noResults
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                tmp,
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h1", {
                                      children: tmp$1,
                                      className: css.title
                                    }),
                                tmp$2
                              ],
                              className: css.heading
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx(UserProjects_List_Input.make, {
                                      filters: state.filters,
                                      update: (function (filters) {
                                          dispatch({
                                                TAG: "UpdateFilter",
                                                _0: filters
                                              });
                                        }),
                                      userViewContext: userViewContext
                                    }),
                                providers.length > 1 ? JsxRuntime.jsx(UserProjects_List_Filter_Provider.make, {
                                        filters: state.filters,
                                        update: (function (filters) {
                                            dispatch({
                                                  TAG: "UpdateFilter",
                                                  _0: filters
                                                });
                                          }),
                                        providers: providers
                                      }) : null,
                                JsxRuntime.jsx(UserProjects_List_Filter_Status.make, {
                                      filters: state.filters,
                                      update: (function (filters) {
                                          dispatch({
                                                TAG: "UpdateFilter",
                                                _0: filters
                                              });
                                        }),
                                      userRole: userRole
                                    }),
                                JsxRuntime.jsx(UserProjects_List_Filter_Category.make, {
                                      filters: state.filters,
                                      update: (function (filters) {
                                          dispatch({
                                                TAG: "UpdateFilter",
                                                _0: filters
                                              });
                                        })
                                    })
                              ],
                              className: css.filters
                            }),
                        tmp$4
                      ],
                      className: css.contentWrapper
                    })
              ],
              className: css.container
            });
}

var make = UserProjects;

export {
  css ,
  reducer ,
  make ,
}
/* css Not a pure module */
