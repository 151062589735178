// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Icon from "../components/Icon/Icon.res.js";
import * as JsxRuntime from "react/jsx-runtime";

function IconPause(props) {
  var __title = props.title;
  var title = __title !== undefined ? __title : "Pause";
  return JsxRuntime.jsx(Icon.make, {
              title: title,
              size: props.size,
              className: props.className,
              children: JsxRuntime.jsxs("g", {
                    children: [
                      JsxRuntime.jsx("rect", {
                            height: "12",
                            width: "3",
                            rx: "1",
                            x: "3",
                            y: "2"
                          }),
                      JsxRuntime.jsx("rect", {
                            height: "12",
                            width: "3",
                            rx: "1",
                            x: "10",
                            y: "2"
                          })
                    ],
                    fill: Icon.mapColor(props.color),
                    fillRule: "evenodd"
                  })
            });
}

var make = IconPause;

export {
  make ,
}
/* Icon Not a pure module */
