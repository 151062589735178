// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Pagination from "../../../../styleguide/components/Pagination/Pagination.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjectsScss from "./UserProjects.scss";
import * as UserProjects_List_Item from "./UserProjects_List_Item.res.js";

var css = UserProjectsScss;

function UserProjects_List(props) {
  var userViewContext = props.userViewContext;
  var updateProposalStatus = props.updateProposalStatus;
  var updateProjectStatus = props.updateProjectStatus;
  var userRole = props.userRole;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx("div", {
                      children: Belt_Array.map(props.projects, (function (project) {
                              return JsxRuntime.jsx(UserProjects_List_Item.make, {
                                          project: project,
                                          userRole: userRole,
                                          updateProjectStatus: updateProjectStatus,
                                          updateProposalStatus: updateProposalStatus,
                                          userViewContext: userViewContext
                                        }, project.id);
                            })),
                      className: css.projectsWrapper
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsx(Pagination.make, {
                            currentPage: props.currentPage,
                            totalPages: props.totalPages,
                            onPageClick: props.onPageClick
                          }),
                      className: css.pagination
                    })
              ]
            });
}

var make = UserProjects_List;

export {
  css ,
  make ,
}
/* css Not a pure module */
