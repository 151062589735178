// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Project from "../../../../models/Project.res.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjects_List_FiltersScss from "./UserProjects_List_Filters.scss";

var css = UserProjects_List_FiltersScss;

function statusList(userRole) {
  switch (userRole) {
    case "Provider" :
        return [
                "Active",
                "Inactive",
                "Finalized"
              ];
    case "User" :
    case "Visitor" :
        return [];
    case "Admin" :
    case "Concierge" :
        return [
                "Active",
                "Review",
                "Inactive",
                "Finalized",
                "Draft"
              ];
    case "Agent" :
        return [
                "Active",
                "Review",
                "Inactive",
                "Finalized"
              ];
    
  }
}

function UserProjects_List_Filter_Status(props) {
  var update = props.update;
  var filters = props.filters;
  var statuses = filters.statuses;
  var tmp;
  if (statuses !== undefined) {
    var x = statuses.length;
    tmp = x !== 0 ? (
        x !== 1 ? String(x) + " statuses" : "1 status"
      ) : "All statuses";
  } else {
    tmp = "All statuses";
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Dropdown.make, {
                    children: [
                      JsxRuntime.jsx(Dropdown.Trigger.make, {
                            className: css.dropdownTrigger,
                            iconSize: "XS",
                            iconColor: "Gray",
                            children: tmp
                          }),
                      JsxRuntime.jsxs(Dropdown.Body.make, {
                            position: {
                              TAG: "Below",
                              _0: "LeftEdge"
                            },
                            className: css.dropdownBody,
                            withButton: true,
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Statuses",
                                    className: css.dropdownBodyHeading
                                  }),
                              Belt_Array.mapWithIndex(statusList(props.userRole), (function (idx, item) {
                                      var statuses = filters.statuses;
                                      return JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(Checkbox.make, {
                                                          id: String(idx),
                                                          size: "SM",
                                                          checked: statuses !== undefined ? (function (__x) {
                                                                  return Js_array.includes(item, __x);
                                                                })(statuses) : false,
                                                          onChange: (function ($$event) {
                                                              var checked = $$event.target.checked;
                                                              var statuses = filters.statuses;
                                                              var statuses$1 = statuses !== undefined ? (
                                                                  checked ? Belt_Array.concat(statuses, [item]) : Belt_Array.keep(statuses, (function (value) {
                                                                            return value !== item;
                                                                          }))
                                                                ) : (
                                                                  checked ? [item] : []
                                                                );
                                                              update({
                                                                    query: filters.query,
                                                                    providerIds: filters.providerIds,
                                                                    statuses: statuses$1,
                                                                    sortBy: filters.sortBy,
                                                                    categories: filters.categories
                                                                  });
                                                            })
                                                        }),
                                                    JsxRuntime.jsx("div", {
                                                          children: Project.Status.toString(item),
                                                          className: css.label
                                                        })
                                                  ],
                                                  className: css.dropdownBodyRow
                                                }, String(idx));
                                    }))
                            ]
                          })
                    ]
                  }),
              className: css.statusFilter
            });
}

var make = UserProjects_List_Filter_Status;

export {
  css ,
  statusList ,
  make ,
}
/* css Not a pure module */
