// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ID from "../../../../libs/ID.res.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjects_List_FiltersScss from "./UserProjects_List_Filters.scss";

var css = UserProjects_List_FiltersScss;

function UserProjects_List_Filter_Provider(props) {
  var update = props.update;
  var filters = props.filters;
  var providerIds = filters.providerIds;
  var tmp;
  if (providerIds !== undefined) {
    var x = providerIds.length;
    tmp = x !== 0 ? (
        x !== 1 ? String(x) + " Providers" : "1 Provider"
      ) : "Provider";
  } else {
    tmp = "Provider";
  }
  return JsxRuntime.jsxs(Dropdown.make, {
              children: [
                JsxRuntime.jsx(Dropdown.Trigger.make, {
                      className: css.dropdownTrigger,
                      iconSize: "XS",
                      iconColor: "Gray",
                      children: tmp
                    }),
                JsxRuntime.jsx(Dropdown.Body.make, {
                      position: {
                        TAG: "Below",
                        _0: "LeftEdge"
                      },
                      className: css.dropdownBody,
                      children: Belt_Array.map(props.providers, (function (provider) {
                              var id = "user-colocation-rfp-projects-list-filter--provider-" + ID.toString(provider.id);
                              var providerIds = filters.providerIds;
                              return JsxRuntime.jsxs("div", {
                                          children: [
                                            JsxRuntime.jsx(Checkbox.make, {
                                                  id: id,
                                                  size: "SM",
                                                  checked: providerIds !== undefined ? (function (__x) {
                                                          return Js_array.includes(provider.id, __x);
                                                        })(providerIds) : false,
                                                  onChange: (function ($$event) {
                                                      var checked = $$event.target.checked;
                                                      var providerIds = filters.providerIds;
                                                      var providerIds$1 = providerIds !== undefined ? (
                                                          checked ? Belt_Array.concat(providerIds, [provider.id]) : Belt_Array.keep(providerIds, (function (value) {
                                                                    return ID.toString(value) !== ID.toString(provider.id);
                                                                  }))
                                                        ) : (
                                                          checked ? [provider.id] : []
                                                        );
                                                      update({
                                                            query: filters.query,
                                                            providerIds: providerIds$1,
                                                            statuses: filters.statuses,
                                                            sortBy: filters.sortBy,
                                                            categories: filters.categories
                                                          });
                                                    })
                                                }),
                                            JsxRuntime.jsx("div", {
                                                  children: provider.name,
                                                  className: css.label
                                                })
                                          ],
                                          className: css.dropdownBodyRow
                                        }, id);
                            }))
                    })
              ]
            });
}

var make = UserProjects_List_Filter_Provider;

export {
  css ,
  make ,
}
/* css Not a pure module */
