// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SearchField from "../../../../styleguide/forms/SearchField/SearchField.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjects_List_FiltersScss from "./UserProjects_List_Filters.scss";

var css = UserProjects_List_FiltersScss;

function UserProjects_List_Input(props) {
  var update = props.update;
  var filters = props.filters;
  var query = filters.query;
  var tmp;
  switch (props.userViewContext) {
    case "Admin" :
        tmp = "Search by title or client name";
        break;
    case "Personal" :
    case "Visitor" :
        tmp = "Search by title";
        break;
    
  }
  return JsxRuntime.jsx(SearchField.make, {
              id: "user-projects-list-filter--address",
              value: query !== undefined ? query : "",
              placeholder: tmp,
              inputClassName: css.searchFieldInput,
              iconClassName: css.searchIcon,
              onChange: (function ($$event) {
                  update({
                        query: $$event.target.value,
                        providerIds: filters.providerIds,
                        statuses: filters.statuses,
                        sortBy: filters.sortBy,
                        categories: filters.categories
                      });
                })
            });
}

var make = UserProjects_List_Input;

export {
  css ,
  make ,
}
/* css Not a pure module */
