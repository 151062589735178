// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Project from "../../../../models/Project.res.js";
import * as Checkbox from "../../../../styleguide/forms/Checkbox/Checkbox.res.js";
import * as Dropdown from "../../../../styleguide/forms/Dropdown/Dropdown.res.js";
import * as Js_array from "rescript/lib/es6/js_array.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjects_List_FiltersScss from "./UserProjects_List_Filters.scss";

var css = UserProjects_List_FiltersScss;

var categoryList = [
  "BareMetal",
  "Colocation",
  "Cloud",
  "Internet",
  "Network"
];

function UserProjects_List_Filter_Category(props) {
  var update = props.update;
  var filters = props.filters;
  var categories = filters.categories;
  var tmp;
  if (categories !== undefined) {
    var x = categories.length;
    tmp = x !== 0 ? (
        x !== 1 ? String(x) + " categories" : "1 category"
      ) : "All categories";
  } else {
    tmp = "All categories";
  }
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsxs(Dropdown.make, {
                    children: [
                      JsxRuntime.jsx(Dropdown.Trigger.make, {
                            className: css.dropdownTrigger,
                            iconSize: "XS",
                            iconColor: "Gray",
                            children: tmp
                          }),
                      JsxRuntime.jsxs(Dropdown.Body.make, {
                            position: {
                              TAG: "Below",
                              _0: "LeftEdge"
                            },
                            className: css.dropdownBody,
                            withButton: true,
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Categories",
                                    className: css.dropdownBodyHeading
                                  }),
                              Belt_Array.mapWithIndex(categoryList, (function (idx, item) {
                                      var categories = filters.categories;
                                      return JsxRuntime.jsxs("div", {
                                                  children: [
                                                    JsxRuntime.jsx(Checkbox.make, {
                                                          id: String(idx),
                                                          size: "SM",
                                                          checked: categories !== undefined ? (function (__x) {
                                                                  return Js_array.includes(item, __x);
                                                                })(categories) : false,
                                                          onChange: (function ($$event) {
                                                              var checked = $$event.target.checked;
                                                              var categories = filters.categories;
                                                              var categories$1 = categories !== undefined ? (
                                                                  checked ? Belt_Array.concat(categories, [item]) : Belt_Array.keep(categories, (function (value) {
                                                                            return value !== item;
                                                                          }))
                                                                ) : (
                                                                  checked ? [item] : []
                                                                );
                                                              update({
                                                                    query: filters.query,
                                                                    providerIds: filters.providerIds,
                                                                    statuses: filters.statuses,
                                                                    sortBy: filters.sortBy,
                                                                    categories: categories$1
                                                                  });
                                                            })
                                                        }),
                                                    JsxRuntime.jsx("div", {
                                                          children: Project.ProjectType.toLabel(item),
                                                          className: css.label
                                                        })
                                                  ],
                                                  className: css.dropdownBodyRow
                                                }, String(idx));
                                    }))
                            ]
                          })
                    ]
                  }),
              className: css.categoryFilter
            });
}

var make = UserProjects_List_Filter_Category;

export {
  css ,
  categoryList ,
  make ,
}
/* css Not a pure module */
