// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as $$Date from "../../../../libs/Date.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as NoBidModal from "../../../../styleguide/modals/NoBidModal/NoBidModal.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as PreparingProposalModal from "../../../../styleguide/modals/PreparingProposalModal/PreparingProposalModal.res.js";
import * as ProjectProposalStatusDropdown from "../_components/ProjectProposalStatusDropdown/ProjectProposalStatusDropdown.res.js";
import * as UserProjects_List_ControlsScss from "./UserProjects_List_Controls.scss";

var css = UserProjects_List_ControlsScss;

function UserProjects_List_ControlsForProvider(props) {
  var userRole = props.userRole;
  var projectId = props.projectId;
  var match = React.useState(function () {
        return "Hidden";
      });
  var setBidModalVisibility = match[1];
  var match$1 = React.useState(function () {
        return "Hidden";
      });
  var setPreparingProposalModalVisibility = match$1[1];
  var details = props.projectData.proposalDetails;
  var tmp;
  if (details !== undefined) {
    var match$2 = details.status;
    tmp = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(ProjectProposalStatusDropdown.make, {
                  proposal: details,
                  projectId: projectId,
                  userViewContext: props.userViewContext,
                  iconSize: "XS",
                  updateProposalStatus: props.updateProposalStatus,
                  setBidModalVisibility: (function (visibility) {
                      setBidModalVisibility(function (param) {
                            return visibility;
                          });
                    }),
                  className: css.providerDropdown
                }),
            match$2 === "Preparing" ? null : JsxRuntime.jsxs("div", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Sent",
                                    className: css.proposalLabel
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: $$Date.Naive.format(details.sentAt, "dd MMM yyyy"),
                                    className: css.proposalDate
                                  })
                            ],
                            className: css.proposalDateContainer
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: "Expires",
                                    className: css.proposalLabel
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: $$Date.Naive.format(details.expiresAt, "dd MMM yyyy"),
                                    className: css.proposalDate
                                  })
                            ],
                            className: css.proposalDateContainer
                          })
                    ],
                    className: css.projectProposalDates
                  })
          ]
        });
  } else {
    tmp = JsxRuntime.jsx("div", {
          children: JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  JsxRuntime.jsx(Button.AsLink.make, {
                        href: Routes_Project.Proposal.$$new(projectId),
                        size: "SM",
                        color: "Teal",
                        margin: "RightMargin",
                        children: "Send Proposal"
                      }),
                  JsxRuntime.jsxs(Popover.make, {
                        children: [
                          JsxRuntime.jsx(Popover.Trigger.make, {
                                className: css.popoverIconActions,
                                children: JsxRuntime.jsx(Button.make, {
                                      size: "XS",
                                      color: "Teal",
                                      buttonClassName: css.popoverButton,
                                      children: JsxRuntime.jsx(IconMenu.make, {
                                            size: "LG",
                                            color: "White"
                                          })
                                    })
                              }),
                          JsxRuntime.jsxs(Popover.Body.make, {
                                position: {
                                  TAG: "Below",
                                  _0: "RightEdge"
                                },
                                className: Cx.cx([
                                      css.popoverBody,
                                      css.popoverBodyActionsAdmin,
                                      css.popoverBodyPositionAdmin
                                    ]),
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: "Set Preparing Status",
                                              className: css.sortSelection,
                                              onClick: (function (param) {
                                                  setPreparingProposalModalVisibility(function (param) {
                                                        return "Shown";
                                                      });
                                                })
                                            }),
                                        className: css.popoverRow
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: "No Bid",
                                              className: css.sortSelection,
                                              onClick: (function (param) {
                                                  setBidModalVisibility(function (param) {
                                                        return "Shown";
                                                      });
                                                })
                                            }),
                                        className: css.popoverRow
                                      })
                                ]
                              })
                        ]
                      })
                ]
              }),
          className: css.projectStatusControl
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Proposal status",
                              className: Cx.cx([
                                    css.proposalLabel,
                                    css.status
                                  ])
                            }),
                        tmp
                      ],
                      className: css.projectLocations
                    }),
                match[0] === "Shown" ? JsxRuntime.jsx(NoBidModal.make, {
                        title: "No Bid",
                        close: (function () {
                            setBidModalVisibility(function (param) {
                                  return "Hidden";
                                });
                          }),
                        projectId: projectId,
                        userRole: userRole
                      }) : null,
                match$1[0] === "Shown" ? JsxRuntime.jsx(PreparingProposalModal.make, {
                        title: "Set Preparing Status",
                        close: (function () {
                            setPreparingProposalModalVisibility(function (param) {
                                  return "Hidden";
                                });
                          }),
                        projectId: projectId,
                        userRole: userRole
                      }) : null
              ],
              className: css.projectProviderAdminControls
            });
}

var make = UserProjects_List_ControlsForProvider;

export {
  css ,
  make ,
}
/* css Not a pure module */
