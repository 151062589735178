// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Api from "../../../../api/Api.res.js";
import * as Url from "../../../../utils/Url.res.js";
import * as React from "react";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Toggle from "../../../../styleguide/forms/Toggle/Toggle.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as Popover from "../../../../styleguide/components/Popover/Popover.res.js";
import * as $$Promise from "../../../../bindings/Promise.res.js";
import * as IconMenu from "../../../../styleguide/icons/IconMenu.res.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as SentryLogger from "../../../../loggers/SentryLogger.res.js";
import * as ProjectService from "../../../../services/ProjectService.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserProjects_List_ControlsScss from "./UserProjects_List_Controls.scss";

var css = UserProjects_List_ControlsScss;

function UserProjects_List_ControlsForUser(props) {
  var updateProjectStatus = props.updateProjectStatus;
  var project = props.project;
  var match = React.useState(function () {
        return "Hidden";
      });
  var setDeleteModalVisibility = match[1];
  var tmp;
  if (project.TAG === "Draft") {
    var totalLocations = project.data.totalLocations;
    tmp = totalLocations !== undefined ? totalLocations : 0;
  } else {
    var totalLocations$1 = project.data.totalLocations;
    tmp = totalLocations$1 !== undefined ? totalLocations$1 : 0;
  }
  var tmp$1;
  if (project.TAG === "Draft") {
    var totalProviders = project.data.totalProviders;
    tmp$1 = totalProviders !== undefined ? totalProviders : 0;
  } else {
    var totalProviders$1 = project.data.totalProviders;
    tmp$1 = totalProviders$1 !== undefined ? totalProviders$1 : 0;
  }
  var tmp$2;
  if (project.TAG === "Draft") {
    tmp$2 = null;
  } else {
    var match$1 = project.data;
    var totalProposals = match$1.totalProposals;
    var projectId = project.id;
    if (props.userRole === "Admin") {
      var tmp$3;
      tmp$3 = match$1.status === "Active" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Control.make, {
                      className: css.deleteButton,
                      onClick: (function (param) {
                          setDeleteModalVisibility(function (param) {
                                return "Shown";
                              });
                        }),
                      children: "Delete"
                    }),
                match[0] === "Shown" ? JsxRuntime.jsx("div", {
                        children: JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: "Are you sure?",
                                      className: css.modalMessageText
                                    }),
                                JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Gray",
                                              onClick: (function (param) {
                                                  setDeleteModalVisibility(function (param) {
                                                        return "Hidden";
                                                      });
                                                }),
                                              children: "Cancel"
                                            }),
                                        JsxRuntime.jsx(Button.make, {
                                              size: "SM",
                                              color: "Pink",
                                              onClick: (function (param) {
                                                  $$Promise.wait(Api.deleteProject(projectId), (function (x) {
                                                          if (x.TAG !== "Ok") {
                                                            return SentryLogger.error({
                                                                        rootModule: "UserProjects_List_ControlsForUser",
                                                                        subModulePath: /* [] */0,
                                                                        value: "make",
                                                                        fullPath: "UserProjects_List_ControlsForUser.make"
                                                                      }, "DeleteProject::Error");
                                                          }
                                                          window.location.reload();
                                                        }));
                                                }),
                                              children: "Yes, delete it"
                                            })
                                      ],
                                      className: css.modalMessageButtons
                                    })
                              ],
                              className: css.modalMessageContainer
                            }),
                        className: css.modalMessageOverlay
                      }) : null
              ]
            }) : null;
      tmp$2 = JsxRuntime.jsxs("div", {
            children: [
              JsxRuntime.jsx("a", {
                    children: totalProposals !== undefined && totalProposals !== 0 ? String(totalProposals) + " Proposals" : "No proposals",
                    className: Cx.cx([
                          css.proposalsButton,
                          Belt_Option.getWithDefault(totalProposals, 0) > 0 ? css.activeProposals : ""
                        ]),
                    href: Routes_Project.Proposal.index(projectId)
                  }),
              tmp$3
            ],
            className: css.buttonsContainer
          });
    } else {
      tmp$2 = JsxRuntime.jsx("a", {
            children: totalProposals !== undefined && totalProposals !== 0 ? String(totalProposals) + " Proposals" : "No proposals",
            className: Cx.cx([
                  css.proposalsButton,
                  Belt_Option.getWithDefault(totalProposals, 0) > 0 ? css.activeProposals : ""
                ]),
            href: Routes_Project.Proposal.index(projectId)
          });
    }
  }
  var tmp$4;
  if (project.TAG === "Draft") {
    tmp$4 = null;
  } else {
    var status = project.data.status;
    var category = project.category;
    var projectId$1 = project.id;
    var tmp$5;
    var exit = 0;
    switch (status) {
      case "Active" :
      case "Inactive" :
          exit = 1;
          break;
      default:
        tmp$5 = null;
    }
    if (exit === 1) {
      var tmp$6;
      tmp$6 = status === "Active" ? true : false;
      tmp$5 = JsxRuntime.jsx(Toggle.make, {
            on: tmp$6,
            size: "MD",
            onChange: (function () {
                var tmp;
                switch (status) {
                  case "Draft" :
                      tmp = "Draft";
                      break;
                  case "Active" :
                      tmp = "Inactive";
                      break;
                  case "Inactive" :
                      tmp = "Active";
                      break;
                  case "Finalized" :
                      tmp = "Finalized";
                      break;
                  case "Review" :
                      tmp = "Review";
                      break;
                  
                }
                updateProjectStatus(projectId$1, tmp);
              })
          });
    }
    tmp$4 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx("div", {
                  children: tmp$5,
                  className: css.projectStatus
                }),
            JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsxs(Popover.make, {
                        children: [
                          JsxRuntime.jsx(Popover.Trigger.make, {
                                className: css.popoverIconActions,
                                children: JsxRuntime.jsx(IconMenu.make, {
                                      size: "LG",
                                      color: "Teal"
                                    })
                              }),
                          JsxRuntime.jsxs(Popover.Body.make, {
                                position: {
                                  TAG: "Below",
                                  _0: "RightEdge"
                                },
                                className: Cx.cx([
                                      css.popoverBody,
                                      css.popoverBodyActions
                                    ]),
                                children: [
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: "Edit",
                                              className: css.sortSelection,
                                              onClick: (function (param) {
                                                  Url.visit(Routes_Project.update(projectId$1));
                                                })
                                            }),
                                        className: css.popoverRow
                                      }),
                                  JsxRuntime.jsx("div", {
                                        children: JsxRuntime.jsx("div", {
                                              children: "Clone project",
                                              className: css.sortSelection,
                                              onClick: (function (param) {
                                                  ProjectService.cloneProject(projectId$1, category);
                                                })
                                            }),
                                        className: css.popoverRow
                                      })
                                ]
                              })
                        ]
                      }),
                  className: css.projectActions
                })
          ]
        });
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsx("div", {
                              children: "Locations",
                              className: css.projectLabel
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("span", {
                                      children: String(tmp)
                                    }),
                                " locations, ",
                                JsxRuntime.jsx("span", {
                                      children: String(tmp$1)
                                    }),
                                " providers"
                              ],
                              className: css.projectLocationAndProviders
                            }),
                        tmp$2
                      ],
                      className: css.projectLocations
                    }),
                tmp$4
              ],
              className: css.projectUserControls
            });
}

var make = UserProjects_List_ControlsForUser;

export {
  css ,
  make ,
}
/* css Not a pure module */
